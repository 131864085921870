import React from "react";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";

const SignaxPage: React.FC = () => {
  return(
    <>
      <TextComponent
        type={TextComponentType.H3_LONG}
        rusText={'Во многом интерфейс состоит из сложных элементов — это различные многофункциональные таблицы, файловые вьюеры и графики.'}
        engText={'In many ways, the interface consists of complex elements — a variety of multi-function tables, file viewers, and graphics.'}
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.DOUBLE_MEDIA}
        thirdChild={<ImageComponent src={'https://i.imgur.com/01fYeou.jpeg'} />}
      >
        <ImageComponent src={'https://i.imgur.com/uxqCBq6.jpg'}/>
        <ImageComponent src={'https://i.imgur.com/QyxBZxB.jpg'}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'<p>Дизайн-система для всего комплекса была разработана с нуля и содержит в себе множество уникальных и сложных компонентов. Файловые вьюеры имеют множество сложных комбинаций окон, скрывающихся панелей, сплит-вью и т.д.</p>' +
          '<p>Среда общих данных позволяет работать со всеми файлами, сопровождающей строительный процесс: это просмотр и юридически значимое подписание документов, работа с 3D-моделями, постановка замечаний и пометок, передача комплектов согласования и многое другое. Специфика решения предполагает множество очень узконаправленных задач при проектировании интерфейса, поэтому в целом продукт уникален.</p>'}
        engText={'<p>The design system for the entire platform was built from scratch, incorporating a wide range of unique and complex components. File viewers support intricate combinations of windows, collapsible panels, split views, and more.</p>' +
          '<p>The Common Data Environment enables seamless management of all files related to the construction process, including document viewing and legally binding signing, working with 3D models, adding comments and annotations, sharing approval packages, and much more. The solution’s specificity involves addressing highly specialized tasks in interface design, making the product truly one of a kind.</p>'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/jy2vkJP.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/WhT3irL.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Весь функционал десктопных сервисов полностью адаптирован под мобильные устройства. Процесс реализации мобильной версии для таких проектов периодически нетривиальная задача, но здесь это реальное конкурентное преимущество.'}
        engText={'The full functionality of desktop services has been seamlessly adapted for mobile devices. Implementing a mobile version for projects of this scale is often a complex challenge, but in this case, it serves as a true competitive advantage.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/FbVatrT.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/JWiO3Ns.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/DVuiJnz.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/VC0UhMm.jpeg'} />
      </MediaComponentWrapper>

      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'<p>The design system includes over 170 unique icons, more than 24 types of buttons, various inputs, dropdowns, table and list components, selectors, uploaders, steppers, and other custom UI elements.</p>' +
          '<p>In total, more than 700 screens were designed for the desktop and mobile versions of the module. The project continues to evolve actively.</p>'}
        rusText={'<p>В дизайн-системе более 170 уникальных иконок, более 24 базовых видов кнопок, различные инпуты, дропдауны и компоненты таблиц и списков, селекторы, загрузчики, степперы и другие уникальные UI-элементы.</p>' +
          '<p>Для десктопной и мобильной версии модуля суммарно было задизайнено свыше 700 экранов. Проект активно развивается.</p>'}
      >
        <VimeoPlayer videoId={'930768761'} loop={true} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <VimeoPlayer videoId={'930770240'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={false} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Модуль для стройконтроля использует камеры 360 и позволяет отслеживать ситуацию на стройке, оставлять замечания, загружать фотоальбомы.'}
        engText={'The construction control module uses 360 cameras and allows you to monitor the situation on the construction site, leave comments, upload photo albums.'}
      />
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Для всех модулей используется единая основная дизайн-система + локальные компоненты.'}
        engText={'A single core design system + local components are used for all modules.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/hsyVzIo.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Модуль также полностью адаптирован под мобильные устройства — более половины случае использования именно со смартфонов или планшетов.'}
        engText={'The module is also fully adapted to mobile devices — more than half of the use cases are from smartphones or tablets.'}
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'The interface design of the construction control service contains more than 80 screens for the desktop and mobile version.'}
        rusText={'Дизайн интерфейса сервиса строительного контроля содержит более 80 экранов для десктопной и мобильной версии.'}
      >
        <ImageComponent src={'https://i.imgur.com/YnhLedc.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/i6GGof6.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Буклеты и презентации выполнены в ярких, контрастных цветах, а за основу взят тёмный фон.'}
        engText={'The booklets and presentations are designed in bright, contrasting colors, with a dark background as the base.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/lMB9HGW.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/GG5lWGZ.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/ffR5nTD.jpeg'} />
      </MediaComponentWrapper>
      <Credits lines={[
        {
          rusName: 'Lead designer (UI/UX, graphic):',
          rusVal: 'Глеб Коссов',
          engName: 'Lead designer (UI/UX, graphic):',
          engVal: 'Gleb Kossov'
        },
        {
          rusName: 'Second UI/UX-designer:',
          rusVal: 'Екатерина Ершова, Дарья Беличенко',
          engName: 'Second UI/UX-designer:',
          engVal: 'Ekaterina Ershova, Darya Belichenko'
        },
        {
          engName: 'Licensed mockups:',
          engVal: 'LS graphics',
          link: 'https://www.ls.graphics/'
        }
      ]}/>
    </>
  )
}

export default SignaxPage
